import type { AxiosError, AxiosRequestConfig } from 'axios';

import { setupAxiosInterceptors } from '@utility-nyc/react-auth-setup';
import Axios from 'axios';

const apiAxiosInstance = Axios.create({
  paramsSerializer: { indexes: null },
  baseURL:
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    process.env.NEXT_PUBLIC_SDK_BASE_URL ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    process.env.EXPO_PUBLIC_SDK_BASE_URL ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    process.env.SDK_BASE_URL ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    process.env.NEXT_PUBLIC_BASE_URL ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    process.env.EXPO_PUBLIC_BASE_URL ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    process.env.BASE_URL,
});

setupAxiosInterceptors({
  axiosInstance: apiAxiosInstance,
  shouldAutoLogoutOnFailedSessionRefresh: true,
});

// add a second `options` argument here if you want to pass extra options to each generated query
const customAxios = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> =>
  apiAxiosInstance
    .request({
      ...config,
      ...options,
    })
    .then(({ data }) => data)
    .catch((e) => {
      throw e;
    });

type ErrorType<Error> = AxiosError<Error>;

export { customAxios };
export type { ErrorType };
