import type { RequestingTradeStackNavigationProps } from '@south-street-app/navigation/types';

import { useCallback, useEffect, useState } from 'react';

import { useFocusEffect } from '@react-navigation/native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withSequence,
  withTiming,
} from 'react-native-reanimated';
import { XStack, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { BodyXL, HeadingXL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { ButtonLarge } from '@south-street-app/molecules';
import { TradeCard } from '@south-street-app/organisms';
import { useCountdownRef } from '@south-street-app/providers';
import {
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';

import { tradeCardItems } from './tradeCardItems';

const AnimatedYStack = Animated.createAnimatedComponent(YStack);

const QuotedPriceScreen = ({
  navigation,
  route,
}: RequestingTradeStackNavigationProps<'QuotedPrice'>) => {
  const {
    acceptCounterproposal,
    counterProposal,
    tradeTimeout,
    setReceivedQuotedPrice,
    rejectCounterproposal,
    isNewQuotedPrice,
    setIsNewQuotedPrice,
  } = useMobileTradeStore(
    useShallow((state) => ({
      acceptCounterproposal: state.acceptCounterproposal,
      counterProposal: state.counterProposal,
      tradeTimeout: state.tradeTimeout,
      setReceivedQuotedPrice: state.setReceivedQuotedPrice,
      rejectCounterproposal: state.rejectCounterproposal,
      isNewQuotedPrice: state.isNewQuotedPrice,
      setIsNewQuotedPrice: state.setIsNewQuotedPrice,
    })),
  );

  const { params } = route;

  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const opacity = useSharedValue(1);

  const opacityStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
  }));

  const countdownRef = useCountdownRef();

  useEffect(() => {
    if (isNewQuotedPrice) {
      opacity.value = withSequence(
        withTiming(0, {
          duration: 300,
          easing: Easing.linear,
        }),
        withTiming(1, {
          duration: 300,
          easing: Easing.linear,
        }),
      );

      setTitle('Trader has re-adjusted the quote');

      setDescription('Please review the new quoted price');

      setIsNewQuotedPrice(false);
    }
  }, [counterProposal.id, isNewQuotedPrice, opacity, setIsNewQuotedPrice]);

  useFocusEffect(() => {
    if (params?.title) {
      setTitle(params.title);
    }

    if (params?.description) {
      setDescription(params.description);
    }
  });

  const openBottomSheet = useGlobalBottomSheetStore(
    (globalState) => globalState.openBottomSheet,
  );
  const handleOnPressAccept = useCallback(() => {
    countdownRef.current?.stop();

    acceptCounterproposal();

    setReceivedQuotedPrice(false);

    navigation.navigate('TraderConfirmation');
  }, [acceptCounterproposal, setReceivedQuotedPrice, countdownRef, navigation]);

  const handleOnPressReject = useCallback(() => {
    countdownRef.current?.stop();

    rejectCounterproposal();

    openBottomSheet({
      type: 'tradeTimeout',
      subType: 'rejected',
    });
  }, [countdownRef, openBottomSheet, rejectCounterproposal]);

  const handleOnCountdownCompleted = useCallback(() => {
    openBottomSheet({
      type: 'tradeTimeout',
      subType: 'timeout',
    });
  }, [openBottomSheet]);

  return (
    <YStack flex={1} padding={'$4'} justifyContent={'space-between'}>
      <YStack paddingTop={'$8'}>
        <AnimatedYStack style={opacityStyle}>
          <HeadingXL fontWeight={'$3'}>
            {title ? title : en_US.quotedPrice}
          </HeadingXL>
          <BodyXL paddingTop={'$1'} paddingBottom={'$6'}>
            {description ? description : en_US.acceptOrRejectQuotedPrice}
          </BodyXL>
          <TradeCard
            items={tradeCardItems(counterProposal)}
            countdownSeconds={tradeTimeout}
            onCountdownCompleted={handleOnCountdownCompleted}
          />
        </AnimatedYStack>
      </YStack>
      <XStack width={'$100%'} justifyContent={'space-between'} space={'$2'}>
        <ButtonLarge
          variant={'secondary'}
          color={'$brand500'}
          fontWeight={'$2'}
          flex={1}
          onPress={handleOnPressReject}
        >
          {en_US.reject}
        </ButtonLarge>
        <ButtonLarge variant={'primary'} flex={1} onPress={handleOnPressAccept}>
          {en_US.accept}
        </ButtonLarge>
      </XStack>
    </YStack>
  );
};

export { QuotedPriceScreen };
